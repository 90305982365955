<template>
    <div class="full-height-layout fhf pb-10">
        <mercur-card class="mx-0 fhf u-no-horizontal-overflow">
            <h2 class="font-weight-normal">Artwork settings for: {{ configuration.productConfigurationName }}</h2>

            <div class="row mt-3">
                <!-- Product Export Settings Box -->
                <div class="col-4">
                    <div class="border h-100 position-relative artwork-box">
                        <div class="header-bg header-bg--lighter p-3 mb-20">
                            Product Default Export Settings
                        </div>
                        <div class="artwork-box__content">
                            <div class="row align-items-center py-1 w-100 ml-0"
                                 v-for="(settingValue, settingKey) in artworkExportSettings"
                                 :key="settingKey">
                                <span class="col-4 font-weight-bold">{{ settingKey | beautifyDisplay }}</span>
                                <div class="col-8">
                                    <mercur-select class="m-0" v-model="draftConfiguration.artworkExportSettings[settingKey]">
                                        <template v-if="Array.isArray(settingValue)">
                                            <option v-for="(value, index) in settingValue" :key="settingKey + index" :value="`${value}`">{{ value }}</option>
                                        </template>
                                        <template v-else>
                                            <option v-for="(value, key) in settingValue" :key="key" :value="key">{{ value }}</option>
                                        </template>
                                    </mercur-select>
                                </div>
                            </div>
                        </div>
                        <div class="button-wrapper">
                            <mercur-button
                                class="btn btn-raised btn-yellow btn-sm text-uppercase"
                                @click="saveExportSettings"
                                :disabled="isSaveExportSettingsDisabled">
                                Save
                            </mercur-button>
                        </div>
                    </div>
                </div>

                <!-- Artwork Triggers Box -->
                <div class="col-4">
                    <div class="border h-100 position-relative artwork-box">
                        <div class="header-bg p-3 mb-20 w-100 border-black">
                            ARTWORK TRIGGERS SELECTION
                        </div>
                        <div v-if="productConfiguration" class="artwork-box__content">
                            <div
                                class="flex w-100 py-2 px-3 align-items-center justify-content-between"
                                v-for="attribute in sortedConfigurationAttributes"
                                :key="attribute">
                                <mercur-checkbox
                                    :model="isSelected(attribute)"
                                    :value="attribute"
                                    :disabled="isDefault(attribute)"
                                    :color="isDefault(attribute) && 'black'"
                                    @change="setArtworkTriggers($event, attribute)">
                                    {{ attribute }}
                                </mercur-checkbox>
                            </div>
                        </div>

                        <div class="button-wrapper">
                            <mercur-button
                                class="btn btn-raised btn-yellow btn-sm text-uppercase"
                                @click="saveArtworkTriggers"
                                :disabled="isSaveTriggersDisabled">
                                Save
                            </mercur-button>
                        </div>
                    </div>
                </div>

                <!-- Artwork Internal Naming Box -->
                <div class="col-4">
                    <div class="border h-100 position-relative artwork-box">
                        <div class="header-bg p-3 w-100 border-black">
                            ARTWORK INTERNAL NAMING SETTINGS
                        </div>

                        <div class="artwork-box__content">
                            <div class="d-flex align-items-center pt-3 px-3 artwork-box__inputs mb-4">
                                <mercur-select class="mb-4 flex-grow-1 artwork-box__inputs-select" v-model="selectedArtworkNaming">
                                    <template slot="label">select attribute</template>
                                    <option v-for="(trigger, index) in configuration.artworkTriggers" :key="index" :value="trigger.attribute">{{ trigger.attribute }}</option>
                                </mercur-select>
                                <span class="mx-2">OR</span>
                                <div class="flex-grow-1 d-flex align-items-center">
                                    <mercur-input
                                        class="mb-0"
                                        placeholder="custom textfield"
                                        v-model="customArtworkNaming">
                                    </mercur-input>
                                    <mercur-button class="btn btn-icon btn-icon btn-icon-sm bg-transparent btn-icon-square mt-0" @click="addCustomNaming">
                                        <i class="fas fa-plus"></i>
                                    </mercur-button>
                                </div>
                            </div>

                            <template v-if="configuration && configuration.internalArtworkNaming">
                                <div
                                    class="d-flex align-items-center justify-content-between mx-3 px-2 my-2 naming-setting"
                                    v-for="(naming, index) in configuration.internalArtworkNaming"
                                    :key="naming.value">
                                    <span class="font-italic"> {{ naming.value }} </span>

                                    <div class="flex">
                                        <!-- SORTING ARROWS -->
                                        <div v-if="configuration.internalArtworkNaming.length > 1">
                                            <mercur-button
                                                :disabled="index === configuration.internalArtworkNaming.length - 1"
                                                class="btn btn-icon btn-icon-sm"
                                                @click="changeNamingOrder(index, index + 1)">
                                                <i class="fas fa-angle-down"></i>
                                            </mercur-button>
                                            <mercur-button
                                                :disabled="index === 0"
                                                class="btn btn-icon btn-icon-sm"
                                                @click="changeNamingOrder(index, index - 1)">
                                                <i class="fas fa-angle-up"></i>
                                            </mercur-button>
                                        </div>

                                        <mercur-button
                                            :disabled="isDefault(naming.value)"
                                            @click="removeNaming(index)"
                                            class="btn btn-icon btn-icon-sm">
                                            <i class="fas fa-times"></i>
                                        </mercur-button>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="naming-settings-footer mt-3">
                            Sample:
                        </div>
                    </div>
                </div>
            </div>
        </mercur-card>
        <mercur-card class="mt-15 ml-0 mr-0 text-right">
            <mercur-progress-bar v-if="attemptingToGetResponse" indeterminate></mercur-progress-bar>
            <p class="m-0" v-if="attemptingToGetResponse">Generating unique combinations...</p>
            <mercur-button
                    :disabled="isSavingConfiguration || attemptingToGetResponse"
                    @click.prevent="goToNextStep"
                    class="btn btn-raised btn-yellow text-uppercase">
                <span>Save + Continue</span>
                <i class="fas fa-forward artwork-management-save-icon ml-1"></i>
            </mercur-button>
        </mercur-card>
    </div>
</template>

<script>
import ArtworkManagementConfigurationsMixin from '@/views/artwork_management/configurations/ArtworkManagementConfigurationsMixin'
import CONFIG from '@root/config'
import { mapState } from 'vuex'

export default {
    name: 'ArtworkManagementStartStep',
    mixins: [ ArtworkManagementConfigurationsMixin ],
    data () {
        return {
            selectedArtworkNaming: null,
            customArtworkNaming: '',
            exportSettingEditingKey: null,
            defaultArtworkExportSettings: null,
            artworkExportSettings: {},
            productConfiguration: null,
            draftConfiguration: {},
            recursiveAttempt: 0,
            attemptTimeout: 1000, // 1 second
            attemptingToGetResponse: false,
        }
    },
    watch: {
        selectedArtworkNaming (val) {
            const triggers = Object.values(this.configuration.artworkTriggers).map(trigger => trigger.attribute)
            const internalArtworkNamings = Object.values(this.configuration.internalArtworkNaming).map(naming => naming.value)

            if (internalArtworkNamings.includes(val) || !triggers.includes(val) || val === null) {
                return
            }

            this.configuration.internalArtworkNaming.push({
                value: val,
                type: 'attribute',
            })
            this.selectedArtworkNaming = null
        },
    },
    computed: {
        ...mapState('auth', ['user']),
        isSaveTriggersDisabled () {
            if (!this.configuration || !this.configuration.artworkTriggers) {
                return true
            }

            if (!Object.keys(this.draftConfiguration.artworkTriggers).length) {
                return true
            }

            return JSON.stringify(this.draftConfiguration.artworkTriggers) === JSON.stringify(this.configuration.artworkTriggers)
        },
        isSaveExportSettingsDisabled () {
            if (!this.configuration || !this.configuration.artworkExportSettings) {
                return true
            }

            if (!Object.keys(this.draftConfiguration.artworkExportSettings).length) {
                return true
            }

            return JSON.stringify(this.draftConfiguration.artworkExportSettings) === JSON.stringify(this.configuration.artworkExportSettings)
        },
        sortedConfigurationAttributes () {
            if (!this.productConfiguration) {
                return []
            }

            const attributeKeys = Object.keys(this.productConfiguration.attributeConfiguration.selectedAttributes)
            return attributeKeys.sort((a, b) => this.isDefault(a) ? -1 : this.isDefault(b) ? 1 : 0)
        },
    },
    filters: {
        beautifyDisplay (value) {
            if (!value) {
                return value
            }

            const result = value.replace(/([A-Z])/g, ' $1')
            return result.charAt(0).toUpperCase() + result.slice(1)
        },
    },
    methods: {
        goToNextStep () {
            this.saveConfiguration().then(() => {
                this.attemptingToGetResponse = true
                this.recursiveGetConfiguration()
            })
        },
        recursiveGetConfiguration () {
            if (this.configuration.artworkConfigurationStatus === 'READY') {
                this.$router.push({
                    name: 'UniqueCombinations',
                    params: {
                        ...this.params,
                    },
                })
            } else if (this.configuration.artworkConfigurationStatus === 'FAILED') {
                console.log('Generation failed')
            } else if (this.configuration.artworkConfigurationStatus === 'GENERATION_VARIATIONS') {
                this.$parent.getArtworkConfiguration(true).then(() => {
                    this.recursiveAttempt++
                    setTimeout(this.recursiveGetConfiguration, this.recursiveAttempt * this.attemptTimeout)
                })
            }
        },
        saveExportSettings () {
            this.$set(this.configuration, 'artworkExportSettings', JSON.parse(JSON.stringify(this.draftConfiguration.artworkExportSettings)))
            this.saveConfiguration().then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Export settings were updated',
                    type: 'success',
                })
            })
        },
        saveArtworkTriggers () {
            this.$set(this.configuration, 'artworkTriggers', JSON.parse(JSON.stringify(this.draftConfiguration.artworkTriggers)))

            this.selectedArtworkNaming = null
            const triggers = Object.values(this.configuration.artworkTriggers).map(trigger => trigger.attribute)
            this.configuration.internalArtworkNaming.forEach((naming, i) => {
                if (naming.type !== 'custom' && !triggers.includes(naming.value)) {
                    this.configuration.internalArtworkNaming.splice(i, 1)
                }
            })

            this.$root.$emit('notification:global', {
                message: 'Artwork triggers were updated',
                type: 'success',
            })
        },
        addCustomNaming () {
            if (!this.customArtworkNaming || this.configuration.internalArtworkNaming.map(e => e.value).includes(this.customArtworkNaming)) {
                return
            }
            this.configuration.internalArtworkNaming.push({
                value: this.customArtworkNaming,
                type: 'custom',
            })
            this.$set(this, 'customArtworkNaming', '')
        },
        changeNamingOrder (fromIndex, toIndex) {
            if (fromIndex === 0 && toIndex < 0) {
                return
            }
            const foundElement = this.configuration.internalArtworkNaming[fromIndex]
            this.configuration.internalArtworkNaming.splice(fromIndex, 1)
            this.configuration.internalArtworkNaming.splice(toIndex, 0, foundElement)
        },
        removeNaming (index) {
            this.configuration.internalArtworkNaming.splice(index, 1)
        },
        getDefaultArtworkExportSettings () {
            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.ARTWORK_EXPORT_SETTINGS.LIST_DEFAULT
            this.$api.post(url, {}).then(({ data }) => {
                this.$set(this, 'defaultArtworkExportSettings', data.data)
            })
        },
        getArtworkExportSettings () {
            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.ARTWORK_EXPORT_SETTINGS.LIST
            this.$api.post(url, {}).then(({ data }) => {
                this.$set(this, 'artworkExportSettings', data.data)
            })
        },
        getProductConfigurationData () {
            if (this.user.supplierType !== 'VIRTUAL_SUPPLIER') {
                return
            }
            this.$store.dispatch('products/getProductDetailsForSupplierAndProductConfigurationId', {
                supplierId: this.user.supplierId,
                productConfigurationId: this.$route.params.productConfigurationId,
            }).then(productConfigurationData => {
                this.$set(this, 'productConfiguration', productConfigurationData)
            })
        },
        isSelected (attribute) {
            if (!this.draftConfiguration.artworkTriggers.length) {
                return false
            }

            if (this.draftConfiguration.artworkTriggers.map(e => e.attribute).includes(attribute)) {
                return attribute
            }

            return false
        },
        isDefault (attribute) {
            if (!this.configuration.artworkTriggers) {
                return false
            }

            const trigger = this.configuration.artworkTriggers.find(e => e.attribute === attribute)
            return trigger && trigger.isDefault
        },
        setArtworkTriggers (value, attribute) {
            if (!Object.keys(this.draftConfiguration.artworkTriggers).length) {
                this.draftConfiguration.artworkTriggers = JSON.parse(JSON.stringify(this.configuration.artworkTriggers))
            }

            const trigger = this.draftConfiguration.artworkTriggers.find(e => e.attribute === attribute)
            if (trigger && !value) {
                const index = this.draftConfiguration.artworkTriggers.findIndex(e => e.attribute === attribute)
                this.draftConfiguration.artworkTriggers.splice(index, 1)
                return
            }

            this.draftConfiguration.artworkTriggers.push({
                attribute,
            })
        },
    },

    created () {
        this.getArtworkExportSettings()
        this.getProductConfigurationData()
        if (!this.configuration || !Object.keys(this.configuration).length) {
            this.getDefaultArtworkExportSettings()
        }

        this.$set(this, 'draftConfiguration', JSON.parse(JSON.stringify(this.configuration)))
    },
}
</script>

<style scoped lang="scss">
@import '../../../assets/scss/_variables.scss';
$gray-bg: rgb(242, 242, 242);
.header-bg {
    background-color: $primary-color;
    color: white;

    &--lighter {
        background-color: $light-grey;
        color: $primary-color;
    }
}

.artwork-box {
    display: flex;
    flex-direction: column;

    &__content {
        flex: 1;
    }

    &__inputs {
        background-color: $gray-bg;

        &-select {
            flex: 0 0 42.5%;

            /deep/ label {
                font-size: 12px;
            }
        }

        /deep/ .form-input {
            height: 24px;
            line-height: 20px;
            background-color: transparent;
            font-size: 12px;
            padding-top: 6px;
        }

        /deep/ select.form-input {
            padding-top: 2px;
        }
    }
}

.naming-setting {
    background-color: $gray-bg;
    font-size: 13px;
}

.button-wrapper {
    padding: 0 15px;
    text-align: right;
    margin-bottom: 15px;
}

.naming-settings-footer {
    color: $artwork-sample-blue;
    padding: 20px 10px;
    width: 100%;
    background-color: $gray-bg;
}

.list-item-editing {
    background-color: $artwork-background-yellow;
}
</style>
